<template>
  <div class="elv-report-ledger-container">
    <router-view></router-view>
  </div>
</template>

<script setup lang="ts">
import { useEntityStore } from '@/stores/modules/entity'
import { useLedgerStore } from '@/stores/modules/ledger'
import { useTransactionStore } from '@/stores/modules/transactions'

const route = useRoute()

const entityStore = useEntityStore()
const ledgerStore = useLedgerStore()
const transactionStore = useTransactionStore()

const entityId = computed(() => {
  return String(route.params?.entityId as string)
})

watch(
  () => route,
  async (newValue) => {
    const ledgerRoutes = [
      'entity-ledger-journals',
      'entity-ledger-chart-of-account',
      'entity-ledger-journal-type',
      'reports-general-ledger',
      'entity-ledger-account-balance',
      'entity-ledger-auxiliary-code'
    ]
    if (ledgerRoutes.includes(String(route.name))) {
      console.log('ledger----')
    }
    if (newValue.name === 'entity-ledger-journals') {
      transactionStore.fetchJournalTypeList(entityId.value)
      ledgerStore.fetchJournalCreatedByList(entityId.value)
      entityStore.fetchChartOfAccountList(entityId.value)
    }
  },
  { immediate: true, deep: true }
)
</script>

<style lang="scss" scoped>
.elv-report-ledger-container {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}
</style>
